import React from "react";

class AudioControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div>
                <audio controls
                       muted={true}
                       src={this.props.src}
                       onPause={this.props.onPause}
                       onPlay={this.props.onPlay}/>
            </div>
        )
    }
}

export default AudioControl;