import './App.css';
import {songData} from "./songData";
import ChoralPlayer from "./components/ChoralPlayer";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <ChoralPlayer songData={songData}/>
            </header>
        </div>
    );
}

export default App;
