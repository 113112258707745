import React from "react";

class AudioTrack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: true
        }
        this.audioEl = React.createRef();
    }

    render() {
        return (
            <div className="track-container">
                <div className="track-checkbox-container">
                    <input
                        id={this.props.id}
                        type="checkbox"
                        checked={this.state.enabled} onChange={() => this.checkBoxClickedHandler()}/>
                </div>
                <div className="track-title-container">
                    <label htmlFor={this.props.id}>
                        {this.props.label}
                    </label>
                </div>
                <audio
                    controls={false}
                    src={this.props.src}
                    ref={this.audioEl}
                    muted={!this.state.enabled}
                />
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentTime !== this.props.currentTime) {
            this.updateCurrentTime(this.props.currentTime);
        }

        if (prevProps.playing !== this.props.playing) {
            this.updatePlaying(this.props.playing);
        }
    }

    checkBoxClickedHandler() {
        this.setState({enabled: !this.state.enabled});
    }

    updateCurrentTime(currentTime) {
        const audio = this.audioEl.current;
        audio.currentTime = currentTime;
    }

    updatePlaying(playing) {
        const audio = this.audioEl.current;
        if (playing) {
            audio.play();
        } else {
            audio.pause();
        }
    }

    updateVolume(volume) {
        const audio = this.audioEl.current;
        audio.volume = volume;
    }
}

export default AudioTrack;