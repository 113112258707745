import React from "react";
import AudioTrack from "./AudioTrack";
import AudioControl from "./AudioControl";

class ChoralPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            currentTime: 0     // in seconds
        }
    }

    render() {

        let songName = this.props.songData.song.title;

        let referenceTrackData = this.props.songData.song.tracks.find((trackData) => {
            return trackData.useAsReference === true;
        });

        // TODO Check if referenceTrackData is null

        let trackCount = 0;

        return (
            <div>
                <header>Choral Box</header>
                <div>{songName}</div>
                <br/>
                {this.props.songData.song.tracks.map((trackData, key) => {
                    trackCount ++;
                    return <AudioTrack
                        key={key}
                        id={trackCount}
                        label={trackData.label}
                        src={trackData.src}
                        playing={this.state.playing}
                        currentTime={this.state.currentTime}
                    />
                })
                }
                <br/>

                <AudioControl
                    src={referenceTrackData.src}
                    onPause={this.onPauseHandler.bind(this)}
                    onPlay={this.onPlayHandler.bind(this)}
                />
            </div>
        )
    }

    onPauseHandler(e) {
        this.setState({
            playing: false,
        })
    }

    onPlayHandler(e) {
        this.setState({
            playing: true,
            currentTime: e.target.currentTime
        })
    }

}

export default ChoralPlayer;