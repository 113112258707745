export const songData = {
    "song": {
        "title": "Teardrop",
        "tracks": [
            {
                "label": "TearDrop",
                "src": "./tracks/n1_-_Dr_house_-_Teardrop.mp3",
                "useAsReference": true
            },
            {
                "label": "Soprane",
                "src": "./tracks/n1_-_Dr_house_-_Soprane.mp3"
            },
            {
                "label": "Accompagnement Soprane",
                "src": "./tracks/n1_-_Dr_house_-_Accompagnement_Soprane.mp3"
            },
            {
                "label": "Mezzo",
                "src": "./tracks/n1_-_Dr_house_-_Mezzo.mp3"
            },
            {
                "label": "Accompagnement Mezzo",
                "src": "./tracks/n1_-_Dr_house_-_Accompagnement_Mezzo.mp3"
            },
            {
                "label": "Baryton",
                "src": "./tracks/n1_-_Dr_house_-_Baryton.mp3"
            },
            {
                "label": "Accompagnement Baryton",
                "src": "./tracks/n1_-_Dr_house_-_Accompagnement_Baryton.mp3"
            },
            {
                "label": "Bass",
                "src": "./tracks/n1_-_Dr_house_-_Basse.mp3"
            },
            {
                "label": "Accompagnement Bass",
                "src": "./tracks/n1_-_Dr_house_-_Accompagnement_Basse.mp3"
            },
        ]
    },
};